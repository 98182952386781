.NotificationUsersModal {
  .Heading {
    &_Actions {
      margin-right: 24px;
    }

    &_Search {
      min-width: 20vw;
    }
  }
}
